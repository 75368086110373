import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
} from "victory";

interface BenchmarkChartDataPoint {
  title: string;
  userValue: number;
  benchmarkValue: number;
  // benchmarkStdDevP: number;
}

interface BenchmarkChartProps {
  data: BenchmarkChartDataPoint[];
}

function BenchmarkChart(props: BenchmarkChartProps) {
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={{ x: [50, 50] }}
      width={500}
      height={300}
      padding={{
        top: 10,
        right: 10,
        bottom: 25,
        left: 30,
      }}
    >
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 7,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        style={{
          tickLabels: {
            fontSize: 7,
          },
        }}
      />
      <VictoryBar
        data={props.data}
        x="title"
        y="benchmarkValue"
        labelComponent={
          <VictoryLabel
            dx={15.5}
            dy={11}
            style={{
              fill: "#f0f0f0",
              fontSize: 8,
            }}
          />
        }
        labels={({ datum }) => round(datum.benchmarkValue, 2)}
        alignment="start"
        style={{
          data: {
            fill: "#34a4d5",
          },
        }}
      />
      <VictoryBar
        data={props.data}
        x="title"
        y="userValue"
        labelComponent={
          <VictoryLabel
            dx={-15.5}
            dy={11}
            style={{
              fontSize: 8,
            }}
          />
        }
        labels={({ datum }) => round(datum.userValue, 2)}
        // alignment="middle"
        alignment="end"
        style={{
          data: {
            fill: "#93d1ec",
          },
        }}
      />
    </VictoryChart>
  );
}

function round(val: number, precision: number) {
  const precisionFactor = Math.pow(10, Math.round(precision));
  return Math.round(val * precisionFactor) / precisionFactor;
}

export default BenchmarkChart;
export type { BenchmarkChartDataPoint, BenchmarkChartProps };
