function getChoicesFromURL(url: string): number[][] | null {

  const urlObj = new URL(url);
  const choicesString = urlObj.searchParams.get("choices");
  if (choicesString === null) {
    return null;
  }
  
  const choicesAny = JSON.parse(choicesString);
  // TODO validate!
  return choicesAny as number[][];
}

export default getChoicesFromURL;
