import { compose, drop, flatten, isEmpty, map, mean, take } from "ramda";

/**
 * Bildet von allen Unterlisten den arithmetischen Durchschnitt und fasst die ersten beiden Gruppen von je drei nochmals
 * mittels Durchschnittbildung zusammen.
 */
function calculate(xss: readonly (readonly number[])[]) {
  
  const meanIfNotEmpty = (xs: number[]) => isEmpty(xs) ? [] : [mean(xs)];
  
  const mergeFirstTwoTriplesUsingMean = (xs: number[]) => {

    const fs: Array<((xs: readonly number[]) => number[])> = [
      compose(meanIfNotEmpty, take(3)),
      compose(meanIfNotEmpty, drop(3), take(6)),
      drop(6)
    ];

    const applyToXs = map(
      (f: (xs: readonly number[]) => number[]) => f(xs)
    );

    // // applyAll :: [(a -> b)] -> a -> [b]
    // const applyAll = curry(<A,B>(fs: Array<(a: A) => B>, x: A) => {
    //   return map(f => f(x), fs)
    // });
    // const foo = applyAll(fs);
    // // TODO this does not work

    return compose(flatten, applyToXs) (fs)
  }

  // const mergeFirstTwoTriplesUsingMean = (xs: readonly number[]) => flatten([
  //     compose(meanIfNotEmpty, take(3)) (xs),
  //     compose(meanIfNotEmpty, drop(3), take(6)) (xs) as number[],
  //     drop(6) (xs) as number[]
  // ])

  return compose(mergeFirstTwoTriplesUsingMean, map(mean)) (xss)
} 

export default calculate;
