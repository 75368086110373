import React from "react";
import "./App.css";
import BenchmarkChart, { BenchmarkChartDataPoint } from "../BenchmarkChart";
import calculate from "../../modules/calculate";
import choiceToPercent from "../../modules/choiceToPercent";
import { curry, flip } from "ramda";

function App(props: {
  choices: number[][] | null;
}) {

  if (props.choices === null) {
    return (
      <p>Error: No or invalid data given!</p>
    )
  }

  const choiceOf7ToPercent = flip(curry(choiceToPercent))(7);
  const values = calculate(props.choices).map(choiceOf7ToPercent);

  // TODO Das folgende überarbeiten! Es geht davon aus, dass values 5 Einträge hat, was nicht validiert wurde!
  const data: BenchmarkChartDataPoint[] = [
    {
      title: "Project Portfolio Quality",
      // userValue: 54.4,
      userValue: values[0],
      benchmarkValue: 65.83,
      // benchmarkStdDevP: 2.13,
    },
    {
      title: "Front-End Success",
      // userValue: 42.15,
      userValue: values[1],
      benchmarkValue: 58.11,
      // benchmarkStdDevP: 2.73,
    },
    {
      title: "Ideation Strategy",
      // userValue: 43.4,
      userValue: values[2],
      benchmarkValue: 67.5,
      // benchmarkStdDevP: 1.09,
    },
    {
      title: "Process Formalization",
      // userValue: 15.06,
      userValue: values[3],
      benchmarkValue: 57.83,
      // benchmarkStdDevP: 1.52,
    },
    {
      title: "Creative Encouragement",
      // userValue: 39.06,
      userValue: values[4],
      benchmarkValue: 55,
      // benchmarkStdDevP: 1.08,
    },
  ];
  return <BenchmarkChart data={data} />;
}

export default App;
